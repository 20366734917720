import Hyperlink from '../components/Hyperlink';

export default function Landing() {
    return (
        <div className="flex-none w-80 sm:w-[36rem] md:w-[48rem] md:px-6">
            <div className="py-20 text-2xl font-medium text-white font-inter md:py-36">
                <p className="mb-10">
                    At InPerson, we are building next-generation holographic
                    video conferencing technology using state-of-the-art
                    machine learning. Think Zoom, but in 3D.
                </p>
                <p className="mb-10">
                    Our team has both academic and industry experience in
                    deep learning. We have also contributed to open-source in
                    mixed reality, and were part of the Apple Vision Pro team.
                </p>
                <p className="mb-10">
                    If you're interested in joining us, please send us an email
                    by clicking <Hyperlink
                        text="here"
                        link="mailto:edward@inperson.design"
                    />.
                </p>
                <p className="mb-5">
                    We are backed by Nat Friedman and Daniel Gross.
                </p>
            </div>
        </div>
    );
};
