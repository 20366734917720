export default function Hyperlink({ text, link, color = "text-gray-400" }) {
    return (
        <a
            href={`${link}`}
            className={`font-semibold ${color} hover:underline`}
        >
            { text }
        </a>
    );
};
