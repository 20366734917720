import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Landing from './pages/landing';

function App() {
    return (
        <BrowserRouter>
            <div className="min-h-screen bg-black">
                <div className="flex justify-center">
                    <Routes>
                        <Route exact path="/" element={<Landing />} />
                    </Routes>
                </div>
                    <Routes>
                    </Routes>
            </div>
        </BrowserRouter>
    );
}

export default App;
